export const settlement = {
    common: {
        floor: 'Floor',
        room: 'room',
        owners: 'Owners',
    },
    realty_types: {
        undefined: "Indefinite",
        apartment: "Flat",
        parking_space: "Parking space",
        storage: "Storeroom",
        non_residential: "Non-residential",
        studio: "Studio",
    },
    acceptance_status: {
        get_keys: 'Get the keys',
        information: 'Information',
    },
    acceptance_status_manager: {
        undefined: "Undefined",
        invited: "Invited",
        new: "New",
        appointmented: "Appointmented",
        prepared: "Prepared",
        successResult: "Completed without comments",
        resultWithRemarks: "Completed with comments",
        failResult: "Completed without an acceptance signature",
        cancelled: "Cancelled",
    },
    client: {
        list: {
            empty: 'The list of objects is currently empty',
            emptyDescription: 'After the purchase, all your objects will appear here',
            toRealtyCatalog: 'Go to the catalog'
        },
        appointment: {
            title: 'Making an appointment',
            text: 'Specify who will be present at the acceptance during the period',
            info: {
                title: 'Information for the owner',
                text: 'If a real estate object was acquired in shared ownership (several participants), then all participants in shared construction specified in the contract must be present at its acceptance.',
            },
            presence: {
                yourself: 'By yourself',
                representative: 'Representative by notarial power of attorney',
            },
        },
        personal_data: {
            title: 'Check your details',
            text: 'If the data is incorrect, you can edit it, or mark it for the manager that the data is outdated.',
            form: {
                basic: 'Basic',
                passport: 'Passport',
                passportSeries: 'Series and number',
                isAddressMatch: 'The address of the actual residence coincides with the address of the residence permit',
            },
        },
        date: {
            title: 'Select the date and time of acceptance',
            text1: 'Acceptance for your facility will take place',
            text2: 'Choose a convenient time for you in this period of dates.',
            no_dates: 'All the dates are busy right now. Please contact customer support.',
            dialog: {
                title: 'Acceptance is scheduled',
                text: 'The meeting will be held on',
            },
            dayValidation: 'The date must be no earlier than 2 days from the current date'
        },
        scheduled: {
            title: 'Acceptance is scheduled',
            date: 'Date of acceptance',
            changeDateTime: 'Postpone',
            cancel: 'Cancel acceptance',
            info: {
                title: 'Please arrive 10 minutes before your appointment with your passport.',
                text: 'All parties to the agreement must be present. The act is signed by the party or his authorized representative under a notarized power of attorney.',
            },
            dialog_cancel: {
                title: 'Cancellation of acceptance',
                placeholder: 'Be sure to specify the reason for the cancellation.',
            },
            dialog_success: {
                title: 'Acceptance has been cancelled',
                text: 'You can make an appointment for another available date and time.',
            },
            changing_date_time: {
                title: 'Reschedule acceptance',
                text: 'Acceptance of your object will take place between {from} and {to}. Choose a convenient time for you within this period of dates.'
            }
        },
        completed: {
            remarks: 'Identified remarks',
            acceptance: {
                success: {
                    title: 'Acceptance completed',
                    alert: {
                        title: 'Congratulations',
                        text: 'The object has been successfully accepted.',
                    }
                },
                remarks: {
                    title: 'Acceptance completed with remarks',
                    alert: {
                        title: 'Acceptance completed with remarks',
                        text: 'A technical request has been created based on the remarks and forwarded to the specialist. Once the work is completed, we will invite you again for an inspection.',
                    }
                },
                withoutSign: {
                    title: 'Completed without the signature of the acceptance act',
                    alert: {
                        title: 'Completed without the signature of the acceptance act',
                        text: 'A technical request has been created based on the remarks and forwarded to the specialist. Once the work is completed, we will invite you again for an inspection.',
                    }
                }
            }
        },
    },
    admin: {
        list: {
            title: "Real estate",
            tabs: {
                realty: "Realty",
                houses: "Houses"
            },
            house: {
                title: "House",
                acceptanceDays: "Acceptance days",
                minTimeSlotDuration: "Duration of the minimum slot",
                roomCount: "room"
            },
            excel : {
                import: "Import",
                importStarted: "Import started"
            },
            acceptance: {
                sended: "Invitations for the acceptance have been sent out."
            },
            select: {
                sendMessage: 'Send message',
                objects: 'object/-s',
                resetSelect: 'Reset'
            },
            table: {
                filter: {
                    placholder: {
                        house: 'House',
                        status: 'Status',
                        realtyType: 'Real estate type',
                        realtyNumber: '№',
                        client: 'Client'
                    },
                },
                acceptance: {
                    sended: "Invitations for the acceptance have been sent out."
                },
                columns: {
                    object: "Object",
                    status: "Status",
                    owner: "Owner",
                    phoneNumber: "Phone",
                    address: "Address",
                    startAcceptance: "Start of acceptance",
                    finishAcceptance: "Finish of acceptance",
                },
                edit_disabled: 'The owner of the accepted object cannot be changed',
            },
            acceptance_dialog: {
                button_open: "Make an appointment for acceptance"
            },
            realty: {
                title: 'Realty',
                add_button: 'Add an owner',
                new_button: 'New owner',
                no_phone: 'Phone number is not specified',
                owner: {
                    title_new: 'New owner',
                    title_edit: 'Owner',
                },
                delete_modal: {
                    title: 'Remove the owner from the property?',
                    text: 'You can add the owner again later.',
                },
                close_modal: {
                    title: 'The changes are not saved',
                    text: 'The changes made will not be saved when the window is closed.',
                },
                notifications: {
                    created: 'A new owner has been created',
                    deleted: 'The owner has been removed from the property',
                    added: 'The owner is linked to the property',
                    changed: "Owner's details have been changed",
                    error: "Couldn't save the changes",
                },
            }
        },
        monitoring: {
            setSchedule: 'Set a schedule',
            planningTime1: 'Work time',
            planningTime2: 'planning',
            selectHouseNumber: 'Select a house number',
            houseNumber: 'House number',
            workingDays: 'Working days',
            managers: 'Managers',
            timeSlotDescription: 'Create available time slots for managers on the specified dates and times so that clients can schedule appointments to receive keys.',
            scheduleSaved: 'The schedule is set',
            placeholders: {
                interval: 'Time interval',
                houses: 'House numbers',
            },
            month: {
                workingHours: 'Working hours',
                realtyCount: 'Number of realties',
                managers: 'Managers',
            },
            errors: {
                dayOfWeek: 'You must select a day of the week',
                rangeDateSchedule: 'The selected slot dates must be within the home acceptance schedule.',
                notSchedule: 'It is necessary to set the dates for the acceptance of the house.'
            }
        },
        acceptances: {
            title: 'Acceptance appointments',
            unload: 'Upload schedule',
            unloading_file_name: 'Unloading acceptances',
            change_status_message: 'The status of the properties will be changed',
            sms_invitation_sent_message: 'Invitation messages will be sent to all property owners',
            placeholders: {
                house: 'All the house',
                status: 'Status',
                type: 'Type',
                object_number: 'Object number',
                client: 'Client',
                date: 'All dates',
                manager: 'Choose a manager',
                accepter: 'Enter the name of the receiver',
                comment: 'Enter a technical comment',
                remarks: 'Enter the comments',
            },
            table: {
                address: 'Address',
                section: 'Section',
                number: 'Number',
                date: 'Date and time',
                status: 'Status',
                fullNameManager: 'Manages',
            },
            realty_info: {
                realtyTypeName: "Type of rooms",
                square: "Total area",
                floorNumber: "Floor",
                finishing: "Finishing",
                projectName: "Project",
                sectionNumber: "Section",
            },
            dialog_titles: {
                meeting: 'Meeting',
                date: 'Date and time of the visit',
                manager: 'Manager',
                accepter: 'Accepter',
                comment: 'Technical comment',
                remarks: 'Remarks',
                messageText: 'Message Text',
                messageTags: 'Tags',
                sendSmsDialog: 'Send SMS'
            },
            dialog_owner: {
                passportSeries: 'Series and number',
                passportIssued: 'Issued by',
                passportDate: 'Date of issue',
                passportCode: 'Department code',
                registrationAddress: 'Address at the place of residence',
            },
            errors: {
                status: "The acceptance status is not selected",
                time: "Select the date and time of acceptance",
            },
        },
    },
}
