export const forms = {
    validations: {
        required: "Value is required",
        minLength: "At least {min} characters.",
        maxLength: "No more than {max} characters.",
        numeric: "Value must be numeric",
        email: "Invalid format",
        invalidFormat: "Invalid format",
        dateGreaterThanNow: "Date cannot be greater than today's date",
        birthDateAlreadyChanged: "The date of birth has already changed. Please contact your administrator to change again",
        invalidForms: 'Invalid form',
        onlyLetters: 'The field must contain only letters.',
        onlyRussianLetters: 'The field must contain only russian letters.',
        maxFileSize: "Failed to upload the file. The total size of the uploaded file must not exceed 5 MB.",
        agreement: 'Familiarize yourself with the information',
        ageLessThan14: 'Registration is only possible from 14 years of age',
        send_sms_error: 'Error sending sms',
    },
    shared: {
        phrases: {
            resetFilter: 'Reset filters',
            clipboard: 'Successfully buffered',
            personalPolicy: 'Personal data policy',
            cookiePolicy: 'Cookie policy',
            personalData: 'Consent to the processing of personal data',
            feedback: 'Found a mistake? Write to ',
            copy: 'Copy',
            dataSaved: 'Data saved successfully',
            leaveQuestion: 'Do you really want to leave? you have unsaved changes!',
            attention: 'Attention',
            have_read: 'I have read the information',
            continue: 'Continue',
            personalData1: 'Consent to',
            personalData2: 'the processing of personal data',
            selectAll: 'Select all',
            startDate: 'Start date',
            endDate: 'End date',
            save: 'Save',
            setup: 'Set up',
            confirm: 'Confirm',
            pay: 'Pay',
            more: 'More detailed',
            sendCode: 'Input the code sent to your phone',
            close: 'Close',
            delete: 'Delete',
            dont_save: "Don't save",
            return: 'Return',
            sms: 'To continue working, enter the code from the SMS sent to the number',
            dayOfWeek: {
                short: {
                    monday: 'Mon',
                    tuesday: 'Tue',
                    wednesday: 'Wed',
                    thursday: 'Thu',
                    friday: 'Fri',
                    saturday: 'Sat',
                    sunday: 'Sun'
                }
            }
        },
        back: 'Back',
        well: 'Well',
        dropdown: {
            select: 'Select',
            not_selected: 'Not selected',
        },
        yesNo: {
            yes: 'Yes',
            no: 'No'
        },
        date: {
            placeholder: 'DD.MM.YYYY',
            label: "Date",
        },
        time: {
            placeholder: "HH:mm",
            label: "Time",
        },
        period: {
            minutes: 'Minutes',
            week: "Week",
            day: "Day",
            month: "Month",
            months: 'Months',
            months_genitive: 'Months',
            years: 'Years',
            month_short: 'Mo',
            days: "Days",
            day_genitive: "Days",
        },
        text: {
            show: "Show",
            hide: "Hide"
        },
        email: {
            isVerified: "Mail address confirmed",
            isNotVerified: "Mail address not confirmed",
            emailVerifyMessageSent: "We have sent an e-mail to the indicated mail, please confirm receipt",
            verifyMessageResend: "Resend"
        },
        grid: {
            loading: "Loading...",
            load: "Load",
            outOf: "out of",
            noDataTitle: 'Nothing was found',
            noDataText: 'Simplify your request and try again'
        },
        notification: {
            success: 'Operation succeeded',
            error: 'Error',
            warning: 'Attention',
            information: 'Additional information'
        },
        fileInput: {
            download: 'Download file'
        },
        prepositions: {
            from: 'from',
            from_1: 'from',
            to: 'to',
            at: 'at',
            until: 'until',
            outOf: "out of",
        },
        dimensions: {
            meters: 'm',
            meters2: 'm²',
        },
        sex: {
            man: 'Man',
            woman: 'Woman',
            not_specified: 'Not specified',
        },
        terms: {
            apartment: 'Apartment',
            address: 'Address',
            object: 'Object',
            owner: 'Owner',
        },
        all: 'All',
        go_to: 'Go to',
    },
    labels: {
        lastName: 'Last Name',
        firstName: 'First Name',
        patronymicName: 'Patronymic Name',
        birthDate: 'Birth Date',
        placeOfBirth: 'Place of Birth',
        email: 'Email',
        passportSeriesNumber: 'Passport series and number',
        passportDate: 'Date of issue',
        passportCode: 'Department code',
        passportIssued: 'Issued by whom',
        registrationAddress: 'Address at the place of residence',
        residenceAddress: 'Address of the actual residence',
        phoneNumber: 'Phone number',
        not_necessarily: 'Not necessarily',
    },
    placeholders: {
        lastName: 'Enter Last Name',
        firstName: 'Enter First Name',
        patronymicName: 'Enter Patronymic Name (if any)',
        birthDate: 'Enter Birth Date',
        placeOfBirth: 'Enter Place of Birth',
        email: 'Enter Email',
        passportSeries: 'Enter the passport series',
        passportNumber: 'Enter the passport number',
        passportDate: 'Enter the date of issue',
        passportCode: 'Enter the department code',
        passportIssued: 'Enter the name of the department',
        registrationAddress: 'Enter the address at the place of residence',
        residenceAddress: 'Enter the address of residence',
    },
    tags: {
        address: "Realty address",
        number: "BTI number of the realty",
        realtyType: "Realty type",
        fullName: "Recipient's full name",
        settlementLink: "Link to the Settlement service in the personal account",
        dearGender: "Personalized salutation based on the client's gender"
    }
};